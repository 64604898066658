import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import styled, { css } from 'styled-components'
import { FiSearch } from 'react-icons/fi'
import InputSpinner from '../UI/Spinners/InputSpinner'

interface Props {
  filter: string
  setFilter: (value: string) => void
}

interface StyledProps {
  products?: boolean
}

const Search = ({ filter, setFilter }: Props) => {
  const [value, setValue] = useState<string>(filter)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleChange = useAsyncDebounce(value => {
    setIsSearching(false)
    return setFilter(value || undefined)
  }, 500)

  return (
    <StyledSearchWrapper>
      <StyledSearchInput
        type='text'
        placeholder='Search'
        value={value || ''}
        onChange={event => {
          setIsSearching(true)
          setValue(event.target.value)
          handleChange(event.target.value)
        }}
      />
      {isSearching ? <InputSpinner /> : <FiSearch />}
    </StyledSearchWrapper>
  )
}

export default Search

export const StyledSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--border-light-gray);
  margin: 0 0 30px auto;
  padding: 14px 20px;
  transition: all 200ms ease-out;
  width: 332px;
  border-radius: 4px;
  ${({ products }: StyledProps) => {
    if (products) {
      return css`
        margin: 55px 0 0 auto;
      `
    }
  }}
  &:hover {
    border: 1px solid var(--primary-black);
  }
`

export const StyledSearchInput = styled.input`
  border: none;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--border-light-gray);
    font-size: 0.875rem;
  }
`
