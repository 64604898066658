import { useTable, usePagination, useGlobalFilter } from 'react-table'
import { useLocation } from 'react-router-dom'
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5'
import Search from '../../Search/Search'
import * as Styled from './TableItems.styles'

interface Props {
  articleError?: Error
  ordersError?: Error
  data: any
  columns: any
}
const TableItems = ({ data, columns, articleError, ordersError }: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, globalFilter } = state

  const { pathname } = useLocation()
  const text = pathname === '/news' ? 'Article' : pathname === '/orders' ? 'Order' : 'Product'
  const textPlural = pathname === '/news' ? 'Articles' : pathname === '/orders' ? 'Orders' : 'Products'
  const noResultsMsg =
    pathname === '/news'
      ? 'No Articles Found'
      : pathname === '/orders'
      ? 'No Orders Found'
      : 'No Products Found'
  const errorMsg = pathname === '/news' ? articleError?.message : ordersError?.message

  return (
    <>
      {pathname !== '/products' && <Search filter={globalFilter} setFilter={setGlobalFilter} />}
      <Styled.Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Styled.Th noResults={rows.length === 0 ? true : false} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Styled.Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Styled.Td {...cell.getCellProps()}>{cell.render('Cell')}</Styled.Td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Styled.Table>
      {rows.length === 0 && <Styled.NoResults>{!errorMsg ? noResultsMsg : errorMsg}</Styled.NoResults>}
      {rows.length !== 0 && (
        <Styled.PaginationWrapper>
          <Styled.PageInfo>
            {rows.length} {rows.length === 1 ? text : textPlural}
          </Styled.PageInfo>
          <Styled.ButtonWrapper>
            <Styled.PageInfo>
              Page {pageIndex + 1} of {pageOptions.length}
            </Styled.PageInfo>
            <Styled.PaginationButton onClick={previousPage} disabled={!canPreviousPage}>
              <IoChevronBackOutline />
            </Styled.PaginationButton>
            <Styled.PaginationButton onClick={nextPage} disabled={!canNextPage}>
              <IoChevronForwardOutline />
            </Styled.PaginationButton>
          </Styled.ButtonWrapper>
        </Styled.PaginationWrapper>
      )}
    </>
  )
}

export default TableItems
