import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalStyles from './global-styles'
import AuthContextProvider from './context/auth-context'
import OrdersProvider from './context/orders-context'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <OrdersProvider>
        <Router>
          <GlobalStyles />
          <App />
        </Router>
      </OrdersProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
