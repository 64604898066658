import styled, { css } from 'styled-components'

interface StyledProps {
  noResults?: boolean
}

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Th = styled.th`
  font-size: 0.875rem;
  font-weight: var(--regular);
  text-align: left;
  color: #c0c0c0;
  padding-bottom: 7px;
  min-width: 160px;
  max-width: 160px;

  &:nth-child(1) {
    min-width: 200px;
    max-width: 200px;
  }

  &:nth-child(4) {
    min-width: 50px;
    max-width: 50px;
  }

  &:nth-last-child(1) {
    min-width: unset;
    text-align: right;
  }

  ${({ noResults }: StyledProps) => {
    if (noResults) {
      return css`
        &:nth-child(4) {
          min-width: 160px;
          max-width: 160px;
        }
      `
    }
  }}

  @media (min-width: 1600px) {
    font-size: 1rem;
    min-width: 300px;
    max-width: 350px;

    &:nth-child(1) {
      min-width: 300px;
      max-width: 350px;
    }

    &:nth-child(3) {
      min-width: 200px;
      max-width: 200px;
    }

    ${({ noResults }: StyledProps) => {
      if (noResults) {
        return css`
          &:nth-child(3) {
            min-width: 300px;
            max-width: 300px;
          }
          &:nth-child(4) {
            min-width: 300px;
            max-width: 300px;
          }
        `
      }
    }}
  }
`

export const Td = styled.td`
  padding: 10px 10px 10px 0;
  font-size: 0.9375rem;
  color: var(--primary-black);
  border-bottom: 1px solid var(--border-light-gray);
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 160px;
  max-width: 160px;

  &:nth-child(1) {
    padding-right: 20px;
    overflow: hidden;
    min-width: 200px;
    max-width: 200px;

    &:hover {
      text-overflow: clip;
      width: auto;
      white-space: normal;
    }
  }

  &:nth-child(4) {
    min-width: 50px;
    max-width: 50px;
  }

  &:nth-last-child(1) {
    text-align: right;
    padding-right: 0;
  }

  @media (min-width: 1600px) {
    font-size: 1rem;
    padding: 12px 10px 12px 0;
    min-width: 300px;
    max-width: 350px;

    &:nth-child(1) {
      padding-right: 10px;
      min-width: 300px;
      max-width: 350px;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1600px) {
    margin-top: 45px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.125rem;
  outline: none;

  svg {
    transform: translateY(2px);
  }
`

export const PageInfo = styled.p`
  color: var(--dark-gray);
`

export const NoResults = styled.p`
  color: var(--dark-gray);
  font-weight: var(--bold);
  font-size: 1.2rem;
  margin-top: 70px;
  width: 100%;
  text-align: center;
`
