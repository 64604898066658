import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { StyledContainer } from '../../../components/UI/StyledContainer'
import { StyledPageTitle } from '../../../components/UI/StyledPageTitle'
import ArticleForm from '../../../components/ArticleForm/ArticleForm'
import { NewsFormTypes } from '../../../interfaces/news-form.interface'
import { authContext } from '../../../context/auth-context'
import { createResource } from '../../../helpers/createResource'

const AddArticle = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()
  const history = useHistory()

  const onSubmit = async (values: NewsFormTypes) => {
    setIsFormLoading(true)
    const url = 'news/article'
    const newArticle = new FormData()
    newArticle.append('title', values.title)
    newArticle.append('body', values.body)
    newArticle.append('image', values.image)

    createResource({
      url,
      token,
      formData: newArticle,
      setIsFormLoading,
      destroyToken,
      mutate,
      history,
    })
  }

  return (
    <>
      <StyledContainer formContent>
        <StyledPageTitle>Add Article</StyledPageTitle>
        <ArticleForm onSubmit={onSubmit} isFormLoading={isFormLoading} />
      </StyledContainer>
    </>
  )
}

export default AddArticle
