import styled, { css } from 'styled-components'

interface Props {
  dashboard?: string
  product?: string
}

interface StyledProps {
  dashboard?: string
  product?: string
}

const Spinner = ({ dashboard, product }: Props) => {
  return (
    <StyledSpinner dashboard={dashboard} product={product}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledSpinner>
  )
}

export default Spinner

const StyledSpinner = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;

  ${({ dashboard }: StyledProps) => {
    if (dashboard === 'center') {
      return css`
        top: unset;
        transform: unset;
      `
    }
  }}

  ${({ product }: StyledProps) => {
    if (product === 'product') {
      return css`
        top: calc(50% - 100px);
      `
    }
  }}

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--primary-black);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-black) transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
