import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import styled, { css } from 'styled-components'
import TableItems from './TableItems/TableItems'
import { MdDelete, MdShoppingCart } from 'react-icons/md'
import { RiEdit2Fill } from 'react-icons/ri'
import { deleteResourceById } from '../../helpers/deleteResourceById'
import { authContext } from '../../context/auth-context'
import { NEWS, ORDERS, PRODUCTS } from '../../constant/routes'
import { AllNewsProperties } from '../../interfaces/news.interface'
import { OrderTypes } from '../../interfaces/order.interface'

interface Props {
  articles?: AllNewsProperties[]
  orders?: OrderTypes[]
  products?: any
  articleError?: Error
  ordersError?: Error
}

interface OriginalIdType {
  cell: { row: { original: { id: string; mat_id: number; color_code: string } } }
}

interface StyledProps {
  articles?: boolean
  image?: string
}

const Table = ({ articles, orders, products, articleError }: Props) => {
  const { token, destroyToken } = useContext(authContext)
  const { mutate } = useSWRConfig()

  const columns = [
    {
      Header: `${products || orders ? 'Code' : 'Title'}`,
      accessor: `${products || orders ? 'code' : 'title'}`,
    },
    {
      Header: `${articles ? 'Date' : orders ? 'Customer' : 'Color'}`,
      accessor: `${articles ? 'published' : orders ? 'customer' : 'color_code'}`,
    },
    {
      Header: `${orders ? 'Datetime' : 'Image'}`,
      accessor: `${products ? 'photo_url' : orders ? 'datetime' : 'image'}`,
      Cell: ({ value }: { value: string }) => {
        return orders ? (
          <p>{value}</p>
        ) : !value && !orders ? (
          <p>No image found</p>
        ) : (
          <StyledLink href={value} target='_blank' image={value}>
            <StyledImage src={value} alt='Babywalker product image' />
          </StyledLink>
        )
      },
    },
    {
      Header: `${articles ? 'Status' : orders ? 'Price' : 'Sex'}`,
      accessor: `${articles ? 'status' : orders ? 'price' : 'sex_descr'}`,
      Cell: ({ value }: { value: string }) => {
        return (
          <StyledSpan articles={articles ? true : false}>
            {articles ? value : orders ? `${value}€` : value || 'N/A'}
          </StyledSpan>
        )
      },
    },
    {
      Header: 'Actions',
      Cell: ({
        cell: {
          row: { original },
        },
      }: OriginalIdType) => {
        if (products) {
          return (
            <StyledActionsWrapper>
              <Link to={`${PRODUCTS.edit}/${original.mat_id}-${original.color_code}`}>
                <RiEdit2Fill className='edit-icon' />
              </Link>
            </StyledActionsWrapper>
          )
        }

        if (articles) {
          const urlDelete = `news/article/${original.id}/`
          const urlEditLink = `${NEWS.edit}/${original.id}`
          return (
            <StyledActionsWrapper>
              <Link to={urlEditLink}>
                <RiEdit2Fill className='edit-icon' />
              </Link>
              <MdDelete
                onClick={() => deleteResourceById({ url: urlDelete, token, mutate, destroyToken })}
                className='delete-icon'
              />
            </StyledActionsWrapper>
          )
        }

        if (orders) {
          return (
            <StyledActionsWrapper>
              <Link to={`${ORDERS.show}/${original.id}`}>
                <MdShoppingCart className='edit-icon' />
              </Link>
            </StyledActionsWrapper>
          )
        }
      },
    },
  ]

  return (
    <StyledTableContainer>
      <TableItems data={articles || orders || products} columns={columns} articleError={articleError} />
    </StyledTableContainer>
  )
}

export default Table

const StyledTableContainer = styled.div`
  margin-top: 20px;
  width: 100%;

  @media (min-width: 1600px) {
    margin-top: 40px;
  }
`

const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 18px;

  .edit-icon {
    color: var(--primary-black);
    transform: scale(1.2);
  }
  .delete-icon {
    color: var(--delete);
    cursor: pointer;
    transform: scale(1.2);
  }
`

const StyledSpan = styled.span`
  text-transform: capitalize;
  ${({ articles }: StyledProps) => articles && 'color: var(--published)'};
`

const StyledLink = styled.a`
  display: block;
  transition: color 200ms ease-out;
  border: 1px solid var(--border-light-gray);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: relative;

  @media (min-width: 1350px) {
    &:hover::after {
      content: '';
      position: absolute;
      z-index: 10;
      border: 1px solid var(--border-light-gray);
      border-radius: 50%;
      width: 90px;
      height: 90px;
      right: -90px;
      bottom: 10px;

      ${({ image }: StyledProps) =>
        image &&
        css`
          background-image: url(${image});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        `}
    }
  }

  @media (min-width: 1600px) {
    width: 35px;
    height: 35px;
  }
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`
