export const DASHBOARD = '/'
export const LOGIN = '/login'

export const NEWS = {
  main: '/news',
  add: '/news/add-article',
  edit: '/news/edit-article',
}

export const SALES = {
  main: '/sales',
  add: '/sales/add-sale',
  edit: '/sales/edit-sale',
}

export const ORDERS = {
  main: '/orders',
  show: '/orders/order',
}

export const PRODUCTS = {
  main: '/products',
  edit: '/products/edit-product',
}

export const CROSS_SELLING = {
  main: '/cross-selling',
}
