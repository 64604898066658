import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  path: string
  isExact?: boolean
}

const NavLink = ({ children, path, isExact }: Props) => {
  return (
    <StyledLi>
      <Link to={path} exact={isExact} activeClassName='active'>
        {children}
      </Link>
    </StyledLi>
  )
}

export default NavLink

const StyledLi = styled.li`
  margin-bottom: 40px;

  a {
    color: var(--primary-black);
    display: inline-block;
    font-size: 1.125rem;
    font-weight: var(--bold);
    padding: 14px 23px;
    display: flex;
    align-items: center;
  }

  .active {
    background-color: #f5f4f2;
    width: 100%;
  }

  .dashboard-icon {
    display: block;
    font-size: 25px;
    margin-right: 21.5px;
  }

  .news-icon {
    margin-left: 2.5px;
    margin-right: 23.5px;
  }

  .sales-icon {
    margin-left: 2.5px;
    margin-right: 24.5px;
    width: 23px;
  }

  .orders-icon {
    margin-right: 23.5px;
  }

  .product-icon {
    display: block;
    font-size: 19px;
    margin-left: 4px;
    margin-right: 25.5px;
  }
`
