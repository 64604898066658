import React, { useState } from 'react'
import styled from 'styled-components'
import { DebounceInput } from 'react-debounce-input'
import { FiSearch } from 'react-icons/fi'
import InputSpinner from '../../UI/Spinners/InputSpinner'
import { StyledSearchWrapper } from '../../Search/Search'

interface Props {
  searchProducts: (value: string) => void
}
const SearchProducts = ({ searchProducts }: Props) => {
  const [value, setValue] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleChange = (value: string) => {
    searchProducts(value)
    setIsSearching(false)
  }

  return (
    <StyledSearchWrapper products>
      <StyledSearchInput
        minLength={2}
        type='text'
        placeholder='Search'
        value={value}
        onKeyDown={() => setIsSearching(true)}
        debounceTimeout={1500}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
          handleChange(event.target.value)
        }}
      />
      {isSearching ? <InputSpinner /> : <FiSearch />}
    </StyledSearchWrapper>
  )
}

export default SearchProducts

const StyledSearchInput = styled(DebounceInput)`
  border: none;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  width: 100%;

  &::placeholder {
    color: var(--border-light-gray);
    font-size: 0.875rem;
  }
`
